// Fonts
@import url('https://fonts.googleapis.com/css?family=Prompt');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//font awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";